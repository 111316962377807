export const botToken = "7436611429:AAHERV8DTlnwiF-1D7AVCWdH4brDi3vy7sc";
export const botId = "1359425265";

export interface ITeleBot {
   id: string;
   name: string;
   token: string;
}

export class TeleBot implements ITeleBot {
   id: string;
   name: string;
   token: string;
   constructor(id?: string, name?: string, token?: string) {
      this.id = id || botId;
      this.name = name || "SpaceT Admin";
      this.token = token || botToken;
   }

   async sendMessage(name: string, text?: any) {
      if (text && typeof text !== "string") {
         name = name + ": " + "\n" + JSON.stringify(text);
      } else {
         name = name + ": " + "\n" + text;
      }
      console.log("namenamenamenamenamename", name);

      return fetch(`https://api.telegram.org/bot${this.token}/sendMessage?chat_id=${botId}&text=${name}`).then((response) => response.json());
   }
   async getBotUpdates() {
      return fetch(`https://api.telegram.org/bot${this.token}/getUpdates`).then((response) => response.json());
   }
   async getUserTelegramId(uniqueString) {
      const { result } = await this.getBotUpdates();
      const messageUpdates = result.filter(({ message }) => message?.text !== undefined);
      const userUpdate = messageUpdates.find(({ message }) => message.text === `/start ${uniqueString}`);
      return userUpdate.message.from.id;
   }
}
